import VueArticleBookmark from '../components/article/bookmark'

if (!window.Agiki) { window.Agiki = {} }
if (!window.Agiki.VueComponents) { window.Agiki.VueComponents = {} }

window.Agiki.VueComponents.Article = (function() {
  function Article() {}
  Article.register = function(selector) {
    if (!selector){
      selector = '';
    }
    $(selector + ' [data-vue-component-article-bookmark]').each((index, element) => {
      let container = $(element);

      let data = {};
      Object.assign(data, container.data());
      delete data.vueComponentArticleBookmark;

      new Vue(Object.assign(VueArticleBookmark,
        {el: container[0], data: data}))
    });
  }
  return Article;
})();

document.addEventListener('turbolinks:load', () => {
  window.Agiki.VueComponents.Article.register()
});

// Требуется централизация события с указанием контекста и без  jquery
//$(document).on("agiki.ideaListUpdated", function(event, data){
//  if(data != undefined && data.selector != undefined){
//    return window.Agiki.VueComponents.Article.register(data.selector)
//  }
//  window.Agiki.VueComponents.Article.register()
//});
