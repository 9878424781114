












export default {
  beforeDestroy: () ->
    $updatedEl = $(this.$turbolinksCachedHTML).attr('data-active', this.active)
    this.$turbolinksCachedHTML = $updatedEl[0].outerHTML
  computed:
    request_method: () ->
      if this.active
        return 'DELETE'
      return 'POST'
    tooltip: () ->
      if this.active
        return window.I18n.pim.ideas.actions.bookmark_remove
      return window.I18n.pim.ideas.actions.bookmark_add
  methods:
    click: () ->
      $.ajax(
        method: this.request_method,
        url: this.url,
        success: (res) ->
          if res.status == 'success'
            this.active = res.active
          $el = $(this.$el)
          $el.find('a').blur()
          $el.foundation('destroy').attr('data-tooltip', '')
          setTimeout () ->
            $el.foundation()
          , 400
        .bind(this)
      )
}
